import React from "react";
import axios from "axios";
import { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [send, setSend] = useState("Send Message");

  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
  });

  const handlePostRequest = async (event) => {
    try {
      event.preventDefault();

      let tempError = {};

      if (!name) {
        setError((prev) => ({ ...prev, name: "Name Is Required" }));
        tempError.name = "Name Is Required";
      } else if (
        !validator.isAlpha(name.replace(/\s+/g, ""), "en-US", { ignore: " " })
      ) {
        setError((prev) => ({
          ...prev,
          name: "Name should only contain letters or spaces",
        }));
        tempError.name = "Name should only contain letters or spaces";
      } else {
        setError((prev) => ({ ...prev, name: "" }));
        tempError.name = "";
      }

      if (!email) {
        setError((prev) => ({ ...prev, email: "Email Is Required" }));
        tempError.number = "Email Is Required";
      } else if (!validator.isEmail(email)) {
        setError((prev) => ({ ...prev, email: "Invalid Email" }));
        tempError.email = "Invalid Email";
      } else {
        setError((prev) => ({ ...prev, email: "" }));
        tempError.email = "";
      }

      if (!message) {
        setError((prev) => ({ ...prev, message: "Message is Required" }));
        tempError.message = "Message Is Required";
      } else {
        setError((prev) => ({ ...prev, message: "" }));
        tempError.message = "";
      }

      const regExForContact =
        /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (!number) {
        setError((prev) => ({ ...prev, number: "Number Is Required" }));
        tempError.number = "Number Is Required";
      } else if (!regExForContact.test(number)) {
        setError((prev) => ({ ...prev, number: "Invalid Mobile Number" }));
        tempError.number = "Invalid Mobile Number";
      } else {
        setError((prev) => ({ ...prev, number: "" }));
        tempError.number = "";
      }

      if (
        tempError.message ||
        tempError.email ||
        tempError.name ||
        tempError.number
      ) {
        return;
      }

      const data = {
        name: name,
        email: email,
        message: message,
        company_id: "F99F49B2-66A1-4ACC-9FA6-AE621CCB2B6F",
        contact: number,
        subject: "1",
      };

      setSend("Sending...");

      let res = await axios.post(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/inquiry/add-inquiry",
        data
      );
      let to = res.data.result[0].result;
      toast.success(to);

      setSend("Send Message");
      setEmail("");
      setName("");
      setMessage("");
      setNumber("");
      setSend("Send Message");
    } catch (error) {
      setEmail("");
      setName("");
      setMessage("");
      setNumber("");
      setSend("Send Message");
      //   toast.error(error.response.data.message);
      //   console.log(error)
      toast.error("Something went wrong!");
    }
  };
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={2000}
          >
            <h6>Here to Help</h6>
            <hr className="border-a" />
            <h4>Contact Us</h4>
            <img class="border-icon" src="images/border-icon.png" />
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-4 col-md-4">
              <div
                className="contact-text"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h2>
                  <i className="fa-solid fa-phone-volume" />
                </h2>
                <h3>Phone</h3>
                <p>+91 6350482876</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div
                className="contact-text"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h2>
                  <i className="fa-solid fa-location-dot" />
                </h2>
                <h3>Address</h3>
                <p>Jaipur (Raj)</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div
                className="contact-text"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h2>
                  <i className="fa-solid fa-envelope" />
                </h2>
                <h3>Email</h3>
                <p> info@skymmd.com</p>
              </div>
            </div>
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h6>Online Enquiry</h6>
                <form onSubmit={(e) => handlePostRequest(e)}>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Name"
                    // required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setError((prev) => ({ ...prev, name: "" }));
                    }}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {error.name}
                  </div>
                  <input
                    className="input-btn"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    // required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError((prev) => ({ ...prev, email: "" }));
                    }}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {error.email}
                  </div>
                  <input
                    className="input-btn"
                    type="text"
                    placeholder="Phone"
                    // required
                    value={number}
                    onChange={(e) => {
                      setNumber(e.target.value);
                      setError((prev) => ({ ...prev, number: "" }));
                    }}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {error.number}
                  </div>
                  <textarea
                    className="input-btn message-btn"
                    placeholder="Message....."
                    // required
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setError((prev) => ({ ...prev, message: "" }));
                    }}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {error.message}
                  </div>
                  <button type="submit" className="book-now-btn mt-4">
                    {send}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <img className="img-fluid" src="images/contact1.gif" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mt-4">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.6274728410403!2d75.80278727609283!3d26.851798562704467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5d69950e079%3A0xe71d3d472f718bd6!2sSUUGAM%20TECHKNOW%20WORLD%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1723294077753!5m2!1sen!2sin"
                  width="100%"
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
