import React from "react";

function TermCond() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={2000}
          >
            <h6> Terms</h6>
            <hr className="border-a" />
            <h4> Terms and Conditions</h4>
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-12 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <div className="policy-text">
                  <h6>Terms and Conditions</h6>
                  <p>
                    Welcome to Sky MMD! These Terms and Conditions ("Terms")
                    govern your use of our platform and services, including
                    booking and listing accommodations, interacting with hosts
                    and guests, and related activities ("Services"). By using
                    our Services, you agree to these Terms.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>1. Services Provided</h6>
                  <p>
                    Sky MMD provides an online platform that allows hosts to
                    list their properties and guests to book accommodations. Our
                    Services include:
                  </p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>• Listing and managing property availability.</p>
                      <p>• Facilitating bookings and payments.</p>
                      <p>• Providing customer support.</p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>2. Account Registration</h6>
                  <p>
                    To use our Services, you must create an account. You agree
                    to:
                  </p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>• Provide accurate and complete information.</p>
                      <p>
                        • Maintain the confidentiality of your account
                        credentials.
                      </p>
                      <p>
                        • Notify us immediately of any unauthorized use of your
                        account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>3. Host Responsibilities</h6>
                  <p>As a host, you agree to:</p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        • Provide accurate and complete descriptions of your
                        property.
                      </p>
                      <p>
                        • Maintain your property in a safe and clean condition.
                      </p>
                      <p>
                        • Honor all confirmed bookings and provide access to the
                        property as agreed.
                      </p>
                      <p>• Comply with all applicable laws and regulations.</p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>4. Guest Responsibilities</h6>
                  <p>As a guest, you agree to:</p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        • Provide accurate and complete information during the
                        booking process.
                      </p>
                      <p>
                        • Respect the property and adhere to any house rules set
                        by the host.
                      </p>
                      <p>
                        • Leave the property in a clean and orderly condition.
                      </p>
                      <p>
                        • Report any damages or issues to the host promptly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>5. Booking and Payments</h6>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        <strong>• Bookings:</strong> All bookings are subject to
                        availability and confirmation by the host.
                      </p>
                      <p>
                        <strong>• Payments:</strong> Payments are processed
                        through our platform. You agree to pay all applicable
                        fees, including any applicable taxes.
                      </p>
                      <p>
                        <strong>• Cancellation:</strong> Cancellation policies
                        vary by listing and are outlined on the property’s
                        listing page. You agree to comply with the host’s
                        cancellation policy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>6. Fees</h6>
                  <p>
                    Sky MMD charges fees for using our platform, including
                    service fees and booking fees. These fees are outlined on
                    our website and may be updated from time to time. You agree
                    to pay all fees associated with your use of our Services.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>7. Cancellation and Refunds</h6>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        <strong>• Host Cancellations:</strong> If a host cancels
                        a booking, Sky MMD will provide a full refund to the
                        guest and may assist in finding alternative
                        accommodations.
                      </p>
                      <p>
                        <strong>• Guest Cancellations:</strong> If a guest
                        cancels a booking, the refund will be processed
                        according to the host’s cancellation policy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>8. Reviews and Ratings</h6>
                  <p>
                    Sky MMD charges fees for using our platform, including
                    service fees and booking fees. These fees are outlined on
                    our website and may be updated from time to time. You agree
                    to pay all fees associated with your use of our Services.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>9. Limitation of Liability</h6>
                  <p>
                    To the fullest extent permitted by law, Sky MMD shall not be
                    liable for any indirect, incidental, special, or
                    consequential damages arising out of or related to the use
                    of our Services. Our total liability for any claim related
                    to our Services is limited to the amount paid for the
                    specific transaction giving rise to the claim.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>10. Privacy Policy</h6>
                  <p>
                    Your use of our Services is governed by our Privacy Policy,
                    which explains how we collect, use, and protect your
                    personal information. By using our Services, you consent to
                    the practices described in our Privacy Policy.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>11. Changes to Terms</h6>
                  <p>
                    Sky MMD may update these Terms from time to time. We will
                    notify you of significant changes. Your continued use of our
                    Services after such changes constitutes your acceptance of
                    the revised Terms.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>12. Governing Law</h6>
                  <p>
                    These Terms are governed by and construed in accordance with
                    the laws of India. Any disputes arising from these Terms or
                    the use of our Services will be held in the jurisdiction of
                    the courts in Jaipur.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>13. Contact Us</h6>
                  <p>
                    If you have any questions about these Terms or our Services,
                    please contact us at:
                  </p>
                  <p>Sky MMD</p>
                  <p className="email-di">Email: info@skymmd.com </p>
                  <p>Phone: 63504 82876</p>
                  <p>Address: Jaipur</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermCond;
