import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer">
      <div className="footer-men">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="footer-text">
                <Link to="/Home" onClick={handleLinkClick}>
                  {" "}
                  <img
                    className="img-fluid"
                    src="images/logo.png"
                    alt="Footer Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="footer-text">
                <ul>
                  <li>
                    {" "}
                    <Link to="/Home" onClick={handleLinkClick}>
                      Home{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="/Aboutus" onClick={handleLinkClick}>
                      About Us{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Gallery" onClick={handleLinkClick}>
                      Gallery{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Faq" onClick={handleLinkClick}>
                      FAQ{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Privacypolicy" onClick={handleLinkClick}>
                      Privacy policy{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/TermCond" onClick={handleLinkClick}>
                      Terms&Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="copyright-text nnn">
                <h2>
                © Copyright 2024 <a href="#">SKY MMD</a> . All Rights Reserved
                </h2>
              </div>
            
            </div>
            <div className="col-lg-5 col-md-4">
              <div className="copyright-text">
                <h2>
                A Site Designed and maintained by   <a
                  style={{ color: "white", marginLeft: "5px" }}
                  href="https://satellitekeyit.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                > <strong className="sss">SatelliteKey IT Solutions</strong>
                </a>
                </h2>
                <a
                  style={{ color: "white", marginLeft: "5px" }}
                  href="https://satellitekeyit.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                
                </a>
              </div>
                    
            
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="copyright-text">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-square-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/skymmd_india/">
                      <i className="fa-brands fa-square-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-square-x-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/skymmd/">
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-square-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
