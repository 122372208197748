
import React, { useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Components/Header/Header.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Router from "./Components/Router/Router.jsx";

function App() {

  useEffect(() => {
    AOS.init();
  }, []);
  // change for test commit
  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <div>
        <Router/>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
