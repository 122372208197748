import React from 'react'

function Offer() {
  return (
<div className="middle">
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>Offers</h6>
        <hr className="border-a" />
        <h4>Special Offer</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-6 m-auto">
          <div className="say-with-right-two">
            <img className="img-fluid" src="images/coming-soon-photo.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Offer