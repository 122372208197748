import React from "react";

function Gallery() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={3000}
          >
            <h6>Gallery</h6>
            <hr className="border-a" />
            <h4>Room Gallery</h4>
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center">
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery1.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery2.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery3.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery4.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery5.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery6.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery7.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery8.jpg" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src="images/gallery1.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
