import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Aboutus from '../Aboutus/Aboutus';
import Gallery from '../Gallery/Gallery'; 
import Offer from "../Offer/Offer";
import Contact from "../Contact/Contact";
import Privacypolicy from '../Privacypolicy/Privacypolicy';
import Faq from '../FAQ/Faq';
import TermCond  from '../Term&Cond/Termcond';



function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/home" element={<Home />} />

      <Route exact path="/" element={<Aboutus/>} />
      <Route path="/Aboutus" element={<Aboutus/>} />

      <Route exact path="/" element={<Gallery/>} />
      <Route path="/Gallery" element={<Gallery/>} />

      <Route exact path="/" element={<Contact/>} />
      <Route path="/Contact" element={<Contact/>} />

      <Route exact path="/" element={<Faq/>} />
      <Route path="/Faq" element={<Faq/>} />

      <Route exact path="/" element={<Offer/>} />
      <Route path="/Offer" element={<Offer/>} />
      
      <Route exact path="/" element={<Privacypolicy/>} />
      <Route path="/Privacypolicy" element={<Privacypolicy/>} />

      
      <Route exact path="/" element={<TermCond/>} />
      <Route path="/TermCond" element={<TermCond/>} />

      <Route exact path="/" element={<Contact/>} />
      <Route path="/contact" element={<Contact/>} />



      </Routes>
  )
}

export default Router