import React from 'react'

function Faq() {
  return (
 <div className="middle">
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        {/* <h6> FAQs</h6> */}
        <hr className="border-a" />
        <h4> FAQs</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-12 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button accordion-button-two" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    1. What is Sky MMD?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p>Sky MMD is a platform that connects homeowners with travelers seeking unique and personalized accommodations. We enable homeowners to list their spaces and provide travelers with the opportunity to stay in a home-like environment, offering an authentic and memorable travel experience.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2. How does Sky MMD work?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p><strong>•	For Hosts:</strong> Sign up and create a listing for your space, including details about your accommodation and availability. Set your pricing and any house rules. Once your listing is live, travelers can browse and book your space. You’ll receive booking requests and can communicate directly with guests through our platform.</p>
                    <p><strong>•	For Travelers:</strong> Search for accommodations based on your preferences, such as location, type of property, and amenities. Review listings, read host profiles, and check guest reviews. Once you find a place you like, you can book it directly through our platform and communicate with the host to finalize details.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    3. How do I become a host?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p>To become a host, simply sign up on our platform and follow the instructions to create a listing for your space. You’ll need to provide details about your property, upload photos, set your pricing, and establish any house rules. After your listing is approved, you can start welcoming guests!</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                    4. What types of properties can I list?
                  </button>
                </h2>
                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> You can list a wide range of properties on Sky MMD, including spare rooms, entire homes, apartments, and unique accommodations such as treehouses or boats. If you have a space that you think would be a great fit for travelers, we encourage you to list it!</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                    5. How can I ensure the safety of my property and guests?
                  </button>
                </h2>
                <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> Sky MMD provides several features to enhance safety:</p>
                    <div className="m-2">
                      <p><strong>•	Identity Verification:</strong> We offer identity verification for both hosts and guests to ensure a trusted community.</p>
                      <p><strong>•	Secure Payments:</strong> All transactions are processed through our secure payment system.</p>
                      <p><strong>•	Customer Support:</strong> Our team is available 24/7 to assist with any issues or concerns.</p>
                      <p><strong>•	Reviews and Ratings:</strong> Both hosts and guests can leave reviews, providing transparency and feedback on each experience.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                    6. What should I do if I encounter an issue during my stay?
                  </button>
                </h2>
                <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> If you encounter any issues during your stay, please contact our customer support team immediately. We’re here to assist with any problems and help resolve them as quickly as possible. You can reach us via email at info@skymmd.com or phone at 63504 82876.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                    7. How can I modify or cancel a booking?
                  </button>
                </h2>
                <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> To modify or cancel a booking, log in to your account and navigate to your reservations. Follow the instructions to make changes or cancel your booking. Be sure to review our cancellation policy, as it may vary depending on the host’s rules and the timing of your request.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                    8. How do I leave a review for my host or guest?
                  </button>
                </h2>
                <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> After your stay, you’ll receive a prompt to leave a review for your host or guest. You can rate your experience and provide feedback on the platform. Your review will help future travelers and hosts make informed decisions.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                    9. Are there any fees associated with using Sky MMD?
                  </button>
                </h2>
                <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> Sky MMD charges a service fee for bookings made through our platform. The fee structure is designed to be transparent, with details provided during the booking process. Hosts also have the option to set their own pricing, which includes any applicable fees.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button accordion-button-two collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapseThree">
                    10. Can I host multiple properties?
                  </button>
                </h2>
                <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body faqs-text">
                    <p> Yes, you can host multiple properties on Sky MMD. Simply create separate listings for each property, and manage them through your account. Each listing can have its own set of details, availability, and pricing.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Faq