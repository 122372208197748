import React from 'react'

function Privacypolicy() {
  return (
<div className="middle">
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6> Privacy Policy</h6>
        <hr className="border-a" />
        <h4> Privacy Policy</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-12 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <div className="policy-text">
              <h6>1. Introduction</h6>
              <p>Welcome to Sky MMD. We are committed to protecting your privacy and ensuring a safe experience for all our users. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our platform, which connects travelers with hosts offering accommodations and other services.</p>
            </div>
            <div className="policy-text">
              <h6>2. Information We Collect</h6>
              <p>We may collect the following types of information:</p>
              <div className="m-3">
                <div className="policy-text">
                  <p><strong>a. Personal Information:</strong></p>
                  <p>•	Contact Information: Name, email address, phone number.</p>
                  <p>•	Account Information: Username, password, profile information.</p>
                  <p>•	Payment Information: Credit card details and billing address.</p>
                </div>
                <div className="policy-text">
                  <p><strong>b. Transaction Information:</strong></p>
                  <p>•	Booking Details: Dates, location, and type of accommodation or service booked.</p>
                  <p>•	Payment Transactions: Records of payments and refunds.</p>
                </div>
                <div className="policy-text">
                  <p><strong>c. Usage Data:</strong></p>
                  <p>•	Site Usage: IP address, browser type, operating system, and activity on our platform.</p>
                  <p>•	Cookies: Information collected through cookies and similar tracking technologies.</p>
                </div>
                <div className="policy-text">
                  <p><strong>d. Communications:</strong></p>
                  <p>•	Messages: Content of communications between users and between users and our support team.</p>
                </div>
              </div>
            </div>
            <div className="policy-text">
              <h6>3. How We Use Your Information</h6>
              <p>We use your information to:</p>
              <div className="m-3">
                <div className="policy-text">
                  <p>•	<strong>Provide Services:</strong> Process bookings, manage reservations, and facilitate payments.</p>
                  <p>•	<strong>Improve Our Platform:</strong> Analyze usage patterns to enhance user experience and functionality.</p>
                  <p>•	<strong>Customer Support:</strong> Respond to inquiries, resolve issues, and provide assistance.</p>
                  <p>•	<strong>Marketing:</strong> Send promotional materials and updates (you can opt out at any time).</p>
                  <p>•	<strong>Legal Compliance:</strong> Comply with legal obligations and protect our rights.</p>
                </div>
              </div>
            </div>
            <div className="policy-text">
              <h6>4. Sharing Your Information</h6>
              <p>We may share your information in the following circumstances:</p>
              <div className="m-3">
                <div className="policy-text">
                  <p><strong>a. With Service Providers:</strong></p>
                  <p>•	We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analytics.</p>
                </div>
                <div className="policy-text">
                  <p><strong>b. With Other Users:</strong></p>
                  <p>•	Your profile information, booking details, and reviews may be visible to other users of our platform.</p>
                </div>
                <div className="policy-text">
                  <p><strong>c. For Legal Reasons:</strong></p>
                  <p>•	We may disclose your information to comply with legal requirements, enforce our terms of service, or protect the rights, property, or safety of our users or others.</p>
                </div>
                <div className="policy-text">
                  <p><strong>d. Business Transfers:</strong></p>
                  <p>•	In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</p>
                </div>
              </div>
            </div>
            <div className="policy-text">
              <h6>5. Data Security</h6>
              <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
            </div>
            <div className="policy-text">
              <h6>6. Your Choices</h6>
              <div className="m-3">
                <div className="policy-text">
                  <p><strong>a. Access and Update:</strong></p>
                  <p>•	You can access and update your personal information through your account settings.</p>
                </div>
                <div className="policy-text">
                  <p><strong>b. Opt-Out:</strong></p>
                  <p>•	You may opt out of receiving promotional communications by following the unsubscribe instructions in those emails.</p>
                </div>
                <div className="policy-text">
                  <p><strong>c. Cookies:</strong></p>
                  <p>•	You can manage cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of our platform.</p>
                </div>
              </div>
            </div>
            <div className="policy-text">
              <h6>7. Data Retention</h6>
              <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, or as otherwise required for our business operations.</p>
            </div>
            <div className="policy-text">
              <h6>8. Children's Privacy</h6>
              <p>Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected such information, we will take steps to delete it.</p>
            </div>
            <div className="policy-text">
              <h6>9. Changes to This Privacy Policy</h6>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
            </div>
            <div className="policy-text">
              <h6>10. Contact Us</h6>
              <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            </div>
            <div className="policy-text">
              <h6>Sky MMD</h6>
              <p className="email-di">Email: info@skymmd.com</p>
              <p>Phone: 63504 82876</p>
              <p>Address: Jaipur</p>
            </div>
            <div className="policy-text">
              <p>By using our platform, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Privacypolicy