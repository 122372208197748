import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
 <div className="header-slider">
  <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item carousel-banner carousel-banner-two active">
        <img src="images/banner3.jpg" className="d-block w-100" alt="Banner" />
        <div className="carousel-caption carousel-caption-two d-none d-md-block" data-aos="zoom-in" data-aos-duration={3000}>
          <div className="banner-text">
            <h5>Welcome to Sky MMD</h5>
            <h6>morning meals &amp; dining</h6>
            <Link to="/Contact" className="book-now-btn book-now-btn-two"> Book Now </Link>
          </div>
        </div>
      </div>
      <div className="carousel-item carousel-banner carousel-banner-two">
        <img src="images/banner2.jpg" className="d-block w-100" alt="Banner" />
        <div className="carousel-caption carousel-caption-two d-none d-md-block" data-aos="zoom-in" data-aos-duration={3000}>
          <div className="banner-text">
            <h5>Welcome to Sky MMD</h5>
            <h6>morning meals &amp; dining</h6>
            <a className="book-now-btn book-now-btn-two" href="contact.html"> Book Now </a>
          </div>
        </div>
      </div>
      <div className="carousel-item carousel-banner carousel-banner-two">
        <img src="images/banner1.jpg" className="d-block w-100" alt="Banner" />
        <div className="carousel-caption carousel-caption-two d-none d-md-block" data-aos="zoom-in" data-aos-duration={3000}>
          <div className="banner-text">
            <h5>Welcome to Sky MMD</h5>
            <h6>morning meals &amp; dining</h6>
            <a className="book-now-btn book-now-btn-two" href="contact.html"> Book Now </a>
          </div>
        </div>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span className="carousel-control-prev-icon prev-icon-color" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span className="carousel-control-next-icon  prev-icon-color" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>


   <div className="middle">
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>About</h6>
        <hr className="border-a" />
        <h4>About Us</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="say-with-right" data-aos="fade-up" data-aos-duration={2000}>
            <img className="img-fluid" src="images/about1.jpg" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <h6>ABOUT US </h6>
            <p >Sky MMD is dedicated to transforming the way people travel by bringing hosts and guests together in the comfort of their own homes. Our platform encourages homeowners to host guests, providing unique and personalized accommodation that go beyond standard lodging options. </p>
            <ul>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Best Rate Guarantee</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Parking</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Wifi</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free use of the Gym Facilities for Residents only</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Electric Car Charging Facilities</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Freeview TV</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Air Conditioning</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> 32" Flat Screen</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Tea/Coffee</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Disabled Access</li>
              <li className="mt-4" data-aos="fade-up" data-aos-duration={3000}><Link to="/Aboutus" className="book-now-btn book-now-btn-2"  data-aos="fade-up"> Read More </Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>Offer</h6>
        <hr className="border-a" />
        <h4>What We Offer</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <h6 className="mb-3">What We Offer</h6>
            <p><strong>For Hosts:</strong> Sky MMD empowers homeowners to become hosts and share their spaces with guests. We provide a seamless process to list your home, set your availability, and manage bookings. Our platform offers tools to help you create a welcoming environment and ensure that your guests have a fantastic experience. Whether you have a spare room or an entire property, Sky MMD helps you turn your space into a thriving travel destination.</p>
            <p><strong>For Travelers:</strong> We offer a diverse selection of accommodations that go beyond the ordinary. From charming cottages and stylish apartments to unique homes and local experiences, Sky MMD helps you find a stay that fits your preferences and provides a genuine connection to the destination. Our user-friendly platform allows you to browse listings, read reviews, and communicate directly with hosts to find the perfect place for your travels.</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="say-with-right" data-aos="fade-up" data-aos-duration={2000}>
            <img className="img-fluid" src="images/offer-photo.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={3000}>
        <h6>Room Gallery</h6>
        <hr className="border-a" />
        <h4>Room Types</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center">
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about.jpg" />
            <h6>Cosy Twin</h6>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about1.jpg" />
            <h6>Cosy Double</h6>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about2.jpg" />
            <h6>Accessible Room</h6>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about3.jpg" />
            <h6>Executive Double</h6>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about4.jpg" />
            <h6>Deluxe Double</h6>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="room-types-men">
            <img className="img-fluid" src="images/stay-about5.jpg" />
            <h6>Deluxe Garden Double</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

  )
}

export default Home