import React from 'react'

function Aboutus() {
  return (
<div className="middle">
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>Stay with us</h6>
        <hr className="border-a" />
        <h4>About Our Rooms</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="say-with-right" data-aos="fade-up" data-aos-duration={2000}>
            <img className="img-fluid" src="images/about1.jpg" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <h6>ABOUT US </h6>
            <p>Sky MMD is dedicated to transforming the way people travel by bringing hosts and guests together in the comfort of their own homes. Our platform encourages homeowners to host guests, providing unique and personalized accommodation that go beyond standard lodging options. Our platform is built on the belief that travel should be more than just a stay; it should be a personal and enriching experience. By connecting homeowners with travelers seeking authentic and cozy accommodations, we strive to make every journey memorable.</p>
            <ul>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Best Rate Guarantee</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Parking</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Wifi</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free use of the Gym Facilities for Residents only</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Electric Car Charging Facilities</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Freeview TV</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Air Conditioning</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> 32" Flat Screen</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Free Tea/Coffee</li>
              <li data-aos="fade-up" data-aos-duration={3000}><i className="fa-regular fa-circle-check" /> Disabled Access</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>Our Values</h6>
        <hr className="border-a" />
        <h4>Our Core Values</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <h6 className="mb-3">Our Core Values</h6>
            <p><strong>Authenticity: </strong>We value the authentic experiences that come from staying in a real home and interacting with real people. Our platform encourages hosts to showcase their unique spaces and share their local knowledge, offering travelers a genuine glimpse into their destinations.</p>
            <p><strong>Transparency:</strong>We operate with transparency and integrity. Our platform is designed to ensure clear communication, fair practices, and reliable interactions between hosts and guests. We strive to build trust through honest and open exchanges.</p>
            <p><strong>Innovation: </strong>We continuously innovate to enhance the travel experience. From developing new features to improving our platform’s usability, we are dedicated to staying at the forefront of technology and user experience.</p>
            <p><strong>Sustainability: </strong>We are committed to promoting sustainable travel practices. By encouraging local hosting and reducing the environmental impact of travel, we support efforts to preserve destinations and promote responsible tourism.</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="say-with-right" data-aos="fade-up" data-aos-duration={2000}>
            <img className="img-fluid" src="images/our-core-photo.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="say-with-men">
    <div className="container">
      <div className="say-with-hedding" data-aos="fade-down" data-aos-duration={2000}>
        <h6>Vision</h6>
        <hr className="border-a" />
        <h4>Our Vision</h4>
        {/* <img class="border-icon" src="images/border-icon.png"> */}
      </div>
      <div className="row why-book-center align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="say-with-right" data-aos="fade-up" data-aos-duration={2000}>
            <img className="img-fluid" src="images/vision-photo.png" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="say-with-left" data-aos="fade-up" data-aos-duration={2000}>
            <h6 className="mb-3">Our Vision</h6>
            <p>Our vision is to transform the way people travel by establishing a global network that connects them with hosts who provide real, home-like accommodations and genuine hospitality. We believe in a travel experience that goes beyond standard tourism, with each stay offering a unique glimpse into the local culture and society. We want to build a world where travelers can completely immerse themselves in their locations, uncovering hidden gems and connecting with locals on a personal level. Through this increased participation, we hope to encourage mutual understanding and respect, enhancing lives and broadening perspectives. Our ultimate goal is to turn travel into a series of meaningful meetings and lifelong experiences, with every destination feeling like a second home and each host like a new friend.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Aboutus